import hyperid from 'hyperid'

const genId = hyperid()

export default class ActivityMessage {
  constructor (status, event, eventId, from, { to = '', message = '', payload = {} }) {
    this.status = status
    this.event = event
    this.eventId = eventId || genId()
    this.to = to
    this.from = from
    this.message = message
    this.payload = payload
  }

  static fromJson (data) {
    const parsed = JSON.parse(data)
    return new ActivityMessage(parsed.status, parsed.event, parsed.eventId, parsed.from, parsed)
  }

  isOk () {
    return this.status === 'ok'
  }

  isRejected () {
    return this.status === 'rejected'
  }
}
