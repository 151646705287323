import { PropertiesService } from '@/common/api'
import { flatMap, map } from 'lodash'
import { valueToTextValueObject } from '@/common/utils'

let cultureData

async function fetch () {
  const { data } = await PropertiesService.get()

  return map(flatMap(data, 'definition'), 'name')
    .toSorted((a, b) => a.localeCompare(b))
    .map(valueToTextValueObject)
}

export const CultureOptions = {
  data () {
    return { cultureOptions: [{ value: '' }] }
  },
  async created () {
    const options = cultureData || (cultureData = await fetch())
    this.cultureOptions = this.cultureOptions.concat(options)
  }
}
