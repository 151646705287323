import Vue from 'vue'
import moment from 'moment'
import { upperFirst } from 'lodash-es'

Vue.filter('uppercase', function (value) {
  if (!value) return ''
  return value.toString().toUpperCase()
})

Vue.filter('upperFirst', upperFirst)

export const API_FORMAT_DATE = 'YYYY-MM-DD'
export const API_FORMAT_DATE_TIME = 'YYYY-MM-DD HH:mm:ss'
export const FORMAT_DATE = 'DD.MM.YYYY'
export const FORMAT_DAY = 'DD.MM.'

const FORMAT_DATE_TIME = 'DD.MM.YYYY HH:mm:ss'
const FORMAT_DATETIME_SHORT = 'DD.MM.YYYY HH:mm'
const FORMAT_TIME_SHORT = 'HH:mm'

function isOlderThanToday (mDate) {
  const today = moment().startOf('day')
  return mDate.isBefore(today)
}

export function parseUserDate (value) {
  return moment(value, FORMAT_DATE)
}

export function parseApiDate (value) {
  return moment(value, API_FORMAT_DATE)
}

export function now () {
  return moment.utc().format(API_FORMAT_DATE_TIME)
}

function parseDateUTC (value) {
  return moment.utc(String(value))
}

export function formatDate (value) {
  if (value) {
    return parseDateUTC(value)
      .local()
      .format(FORMAT_DATE)
  }
}
Vue.filter('formatDate', formatDate)

export function formatDateTime (value) {
  if (value) {
    return parseDateUTC(value)
      .local()
      .format(FORMAT_DATE_TIME)
  }
}
Vue.filter('formatDateTime', formatDateTime)

export function formatDateTimeShort (value) {
  if (value) {
    const mDate = parseDateUTC(value)

    return mDate
      .local()
      .format(
        isOlderThanToday(mDate) ? FORMAT_DATETIME_SHORT : FORMAT_TIME_SHORT
      )
  }
}
Vue.filter('formatDateTimeShort', formatDateTimeShort)

export function formatDateTimeShortPrefixed (value, prefixes = { on: 'am', at: 'um' }) {
  if (value) {
    const mDate = parseDateUTC(value)
    const _isOlderThanToday = isOlderThanToday(mDate)
    const prefix = _isOlderThanToday ? prefixes.on : prefixes.at
    const formatted = mDate
      .local()
      .format(
        _isOlderThanToday ? FORMAT_DATETIME_SHORT : FORMAT_TIME_SHORT
      )

    return `${prefix} ${formatted}`
  }
}
Vue.filter('formatDateTimeShortPrefixed', formatDateTimeShortPrefixed)

export function relativeDateFromNow (value) {
  if (value) {
    return parseDateUTC(value)
      .local()
      .fromNow()
  }
}
Vue.filter('relativeDateFromNow', relativeDateFromNow)

export function asHumanReadableBytes (value) {
  const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if (value) {
    const v = parseInt(value, 10)

    const u = Math.floor(Math.log(v) / Math.log(1024))
    return [(v / Math.pow(1024, u)).toFixed(2), ' ', units[u] || ''].join('')
  }
}
Vue.filter('asHumanReadableBytes', asHumanReadableBytes)

export function appendIfNotEmpty (value, appendix = '') {
  return value ? `${value}${appendix}` : value
}
Vue.filter('appendIfNotEmpty', appendIfNotEmpty)

export function createCurrencyFormatter (opts = { maximumFractionDigits: 0, currency: 'EUR' }, locale = 'de-DE') {
  const f = new Intl.NumberFormat(locale, { maximumFractionDigits: 0, currency: 'EUR', style: 'currency', ...opts })
  return function (value) { return value ? f.format(value) : '' }
}

export const formatEUR = createCurrencyFormatter()
Vue.filter('formatEUR', formatEUR)

export const formatEURCent = createCurrencyFormatter({ maximumFractionDigits: 2 })
Vue.filter('formatEURCent', formatEURCent)
