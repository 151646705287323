import Vue from 'vue'
import Router from 'vue-router'

import store from '@/store'
import ability from '@/ability'

import { isEmpty } from 'lodash'

Vue.use(Router)

const Vertragsdatenbank = () =>
  import(/* webpackChunkName: "view-vertragsdatenbank" */ '@/views/contracts/Vertragsdatenbank')
const VertragsSidebar = () =>
  import(/* webpackChunkName: "view-vertragsdatenbank" */ '@/views/contracts/VertragsSidebar')
const VertragsDrawer = () =>
  import(/* webpackChunkName: "view-vertragsdatenbank" */ '@/views/contracts/VertragsDrawer')

const Sortendatenbank = () =>
  import(/* webpackChunkName: "view-sortendatenbank" */ '@/views/crops/Sortendatenbank')
const SortenSidebar = () =>
  import(/* webpackChunkName: "view-sortendatenbank" */ '@/views/crops/SortenSidebar')
const SortenDrawer = () =>
  import(/* webpackChunkName: "view-sortendatenbank" */ '@/views/crops/SortenDrawer')

const Firmen = () =>
  import(/* webpackChunkName: "view-firmen" */ '@/views/firms/Firmen')
const FirmenSidebar = () =>
  import(/* webpackChunkName: "view-firmen" */ '@/views/firms/FirmenSidebar')
const FirmenDrawer = () =>
  import(/* webpackChunkName: "view-firmen" */ '@/views/firms/FirmenDrawer')

const Users = () =>
  import(/* webpackChunkName: "view-users" */ '@/views/users/Users')
const UsersSidebar = () =>
  import(/* webpackChunkName: "view-users" */ '@/views/users/UsersSidebar')
const UsersDrawer = () =>
  import(/* webpackChunkName: "view-users" */ '@/views/users/UsersDrawer')

const router = new Router({
  mode: 'history',
  scrollBehavior () {
    return { y: 120 }
  },
  routes: [
    { path: '/' }, // see App.redirectToStartRoute
    {
      name: 'vertragsdatenbank',
      path: '/vertragsdatenbank/:id?',
      components: {
        default: Vertragsdatenbank,
        sidebar: VertragsSidebar,
        drawer: VertragsDrawer
      },
      props: {
        default: route => ({ id: parseInt(route.params.id) }),
        sidebar: false,
        drawer: false
      },
      beforeEnter: (to, from, next) => {
        const allowed = isEmpty(ability.rules) || ability.can('access', 'contracts')
        next(allowed)
      },
      meta: { model: 'contract' }
    },
    {
      name: 'sortendatenbank',
      path: '/sortendatenbank/:id?',
      components: {
        default: Sortendatenbank,
        sidebar: SortenSidebar,
        drawer: SortenDrawer
      },
      props: {
        default: route => ({ id: parseInt(route.params.id) }),
        sidebar: false,
        drawer: false
      },
      beforeEnter: (to, from, next) => {
        const allowed = isEmpty(ability.rules) || ability.can('access', 'crops')
        next(allowed)
      },
      meta: { model: 'crop' }
    },
    {
      name: 'login',
      path: '/login',
      component: null
    },
    {
      name: 'logout',
      path: '/logout',
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/logout')

        // any attemt to use next() throws an Exception on the call site (i.e. dirty.js)
        // due to the navigation being redirected (which would be fine, except it's uncatchable)
        //
        // This is due to vue-router wanting clean A->C navigation, but we do A->B->C
        // FIXME: refactor routing in the future

        const url = new URL('/login', window.location)

        if (to.params.reason) {
          url.searchParams.append('logoutReason', to.params.reason)
        }

        window.location = url
      }
    },
    {
      name: 'firmen',
      path: '/firmen/:id?',
      components: {
        default: Firmen,
        sidebar: FirmenSidebar,
        drawer: FirmenDrawer
      },
      props: {
        default: route => ({ id: parseInt(route.params.id) }),
        sidebar: false,
        drawer: false
      },
      beforeEnter: (to, from, next) => {
        const allowed = isEmpty(ability.rules) || ability.can('access', 'firms')
        next(allowed)
      },
      meta: { model: 'firm' }
    },
    {
      name: 'users',
      path: '/users/:id?',
      components: {
        default: Users,
        sidebar: UsersSidebar,
        drawer: UsersDrawer
      },
      props: {
        default: route => ({ id: parseInt(route.params.id) }),
        sidebar: false,
        drawer: false
      },
      beforeEnter: (to, from, next) => {
        const allowed = isEmpty(ability.rules) || ability.can('access', 'users')
        next(allowed)
      }
    },
    {
      name: 'vertragsauswertungen',
      path: '/vertragsauswertungen',
      meta: { requiresFluid: true, noSidebar: true },
      components: {
        default: () => import(/* webpackChunkName: "view-reports" */'@/views/reports/VertragAuswertungen'),
        drawer: () => import(/* webpackChunkName: "view-reports" */'@/views/reports/VertragAuswertungsDrawer')
      },
      beforeEnter: (to, from, next) => {
        const allowed = true // isEmpty(ability.rules) || ability.can('access', 'contracts')
        next(allowed)
      }
    },
    {
      name: 'sortenauswertungen',
      path: '/sortenauswertungen',
      meta: { requiresFluid: true, noSidebar: true },
      components: {
        default: () => import(/* webpackChunkName: "view-reports" */ '@/views/reports/SorteAuswertungen'),
        drawer: () => import(/* webpackChunkName: "view-reports" */'@/views/reports/SorteAuswertungsDrawer')
      },
      beforeEnter: (to, from, next) => {
        const allowed = true // isEmpty(ability.rules) || ability.can('access', 'contracts')
        next(allowed)
      }
    },
    {
      path: '*',
      meta: { noDrawer: true },
      component: () => import('@/views/NotFound'),
      beforeEnter: (to, from, next) => {
        store.commit('drawer/close')
        next()
      }
    }
  ]
})

export default router
