import Vue from 'vue'
import { ContractService, MediaService } from '@/common/api'
import { rerankObjectArray, createAddToFn, createRemoveFromFn, createSetFn } from '@/common/utils/'
import deepFreeze from '@/common/utils/deepFreeze'
import { now } from '@/common/filters'

import { cloneDeep, isEmpty, omit, merge, pick, isEqual, mapValues } from 'lodash'

import {
  LOAD_CONTRACT,
  SAVE_CONTRACT,
  SAVE_DOCUMENT,
  LOAD_DOCUMENT,
  TRASH_CONTRACT,
  UNTRASH_CONTRACT,
  DELETE_CONTRACT
} from './actions.type'

import {
  ADD_OR_REPLACE_CONTRACT_DOCUMENT,
  SET_CONTRACT_DOCUMENTS_SIGNED_BY,
  ADD_CONTRACT_SUBJECT,
  REMOVE_CONTRACT_SUBJECT,
  SET_CONTRACT_SUBJECT,
  SET_CONTRACT,
  SET_CONTRACT_DIRTY,
  SET_CONTRACT_PROPS,
  SET_CONTRACT_TYPE,
  RESET_STATE,
  RESET_PRIMARY,
  RESET_SECONDARY,
  RESET_TITLE,
  RESET_RUNTIME,
  RESET_DOCUMENTS
} from './mutations.type'

function getContacts (contract) {
  return mapValues(pick(contract, [
    'primaryContact',
    'primaryContactRepresentative',
    'secondaryContact',
    'secondaryContactRepresentative']), Number)
}

function haveEqualContacts (contractA, contractB) {
  return isEqual(getContacts(contractA), getContacts(contractB))
}

function prepareWithMeta (data) {
  const newMeta = cloneDeep(initialState._meta)
  newMeta.originalContacts = getContacts(data)
  return {
    ...cloneDeep(initialState),
    ...data,
    _meta: merge(newMeta, data._meta, { loaded_at: now() })
  }
}

export const defaultSubject = deepFreeze({

  id: null,
  rank: 1,
  contract_id: null,
  crop: null,
  culture: '',
  linked_licence_id: null,
  licencePrice: {},
  licencePrice_type: null,
  licencePrice_amount: null,
  licencePrice_unit: null,
  licencePrice_currency: 'EUR',

  otherPrice: {},

  include_countries: [],
  exclude_countries: [],

  remark: null
})

export const defaultSignedBy = deepFreeze({
  legalDepartment: false,
  primaryContact: false,
  secondaryContact: false,
  finale: false
})

export const defaultMeta = deepFreeze({
  dirty: false,
  originalContacts: {},
  created_at: null,
  updated_at: null,
  deleted_at: null,
  modificator: null,
  creator: null,
  data_owned_by: {},
  data_shared_with: []
})

// FIXME Patrik: it is probably wise to refactor state into {current,original,metadata,...}
const initialState = deepFreeze({
  id: null,

  type: '',
  subtype: '',
  primaryContact: 0,
  primaryContactRepresentative: null,
  secondaryContact: 0,
  secondaryContactRepresentative: null,

  title: '',

  reportAreaDate: null,
  reportAmountDate: null,
  billingDate: null,
  validAfterDate: null,

  startDate: null,
  endDate: null,
  isLifetime: false,
  runtimeNote: '',

  reminders: [],

  subjectNote: '',
  subjectsComment: '',
  subjects: [{ id: 0, ...cloneDeep(defaultSubject) }],

  documents: [],
  documentsSignedBy: cloneDeep(defaultSignedBy),
  documentAnnotation: '',

  supersededBy_id: null,
  superseds: null,

  _meta: cloneDeep(defaultMeta)
})

export const state = cloneDeep(initialState)

export const actions = {
  async [LOAD_CONTRACT] ({ commit }, id) {
    Vue.$log.debug('LOAD_CONTRACT', id)

    const { data } = await ContractService.get(id)
    const contract = prepareWithMeta(data)

    commit(SET_CONTRACT, contract)
    return contract
  },
  async [SAVE_CONTRACT] ({ commit }, payload) {
    const toSave = cloneDeep(payload)
    Vue.$log.debug('SAVE_CONTRACT', toSave.id)
    const { data } = await ContractService[toSave.id ? 'post' : 'put'](omit(toSave, ['_meta']))
    const contract = prepareWithMeta(data)

    commit(SET_CONTRACT, contract)
    return contract
  },
  [LOAD_DOCUMENT] (context, id) {
    Vue.$log.debug('LOAD_DOCUMENT', id)
    return MediaService
      .get(id)
      .then(({ data }) => {
        context.commit(ADD_OR_REPLACE_CONTRACT_DOCUMENT, data)
        return data
      })
  },
  [SAVE_DOCUMENT] (context, { formdata, onUploadProgress }) {
    Vue.$log.debug('SAVE_DOCUMENT', formdata.id)
    return MediaService[formdata.id ? 'replace' : 'upload'](
      formdata,
      onUploadProgress
    ).then(({ data }) => {
      context.commit(ADD_OR_REPLACE_CONTRACT_DOCUMENT, data)
      return data
    })
  },
  async [TRASH_CONTRACT] ({ commit }, { id }) {
    Vue.$log.debug('TRASH_CONTRACT')
    const { data } = await ContractService.trash(id)
    Vue.$log.debug('trashed contract:', id)
    commit(SET_CONTRACT, data)
    return data
  },
  async [UNTRASH_CONTRACT] ({ commit }, { id }) {
    Vue.$log.debug('UNTRASH_CONTRACT')
    const { data } = await ContractService.untrash(id)
    Vue.$log.debug('untrashed contract:', id)
    commit(SET_CONTRACT, data)
    return data
  },
  async [DELETE_CONTRACT] ({ commit }, { id }) {
    Vue.$log.debug('DELETE_CONTRACT')
    await ContractService.delete(id)
    Vue.$log.debug('deleted contract:', id)
    commit(RESET_STATE)
  }
}

export const mutations = {
  [SET_CONTRACT_DIRTY] (state) {
    state._meta.dirty = true
  },

  [ADD_CONTRACT_SUBJECT]: createAddToFn('subjects', defaultSubject),
  [REMOVE_CONTRACT_SUBJECT]: createRemoveFromFn('subjects'),
  [SET_CONTRACT_SUBJECT]: createSetFn('subjects'),

  [SET_CONTRACT_DOCUMENTS_SIGNED_BY] (state, signedBy) {
    if (!state.documentsSignedBy) { state.documentsSignedBy = { ...defaultSignedBy } }
    for (const f in signedBy) {
      state.documentsSignedBy[f] = signedBy[f]
    }
    state._meta.dirty = true
  },
  [SET_CONTRACT_TYPE] (state, { type, subtype }) {
    state.type = type
    state.subtype = subtype
    state._meta.dirty = true
  },
  [ADD_OR_REPLACE_CONTRACT_DOCUMENT] (state, media) {
    if (!state.documents) state.documents = []

    const existingIdx = media.id ? state.documents.indexOf(media.id) : -1
    if (existingIdx > -1) {
      state.documents.splice(existingIdx, 1, media.id)
    } else {
      state.documents.push(media.id)
    }
    state._meta.dirty = true
  },
  [SET_CONTRACT] (state, contract) {
    Vue.$log.debug('SET_CONTRACT')
    // ensure 1 entry
    contract.subjects = contract.subjects.length ? contract.subjects : [{}]
    // since subjects is an array of objects, recalc a incrementing id
    rerankObjectArray(contract.subjects, defaultSubject)
    for (const f in state) {
      Vue.set(state, f, contract[f])
    }
  },
  [SET_CONTRACT_PROPS] (state, props) {
    Vue.$log.debug('SET_CONTRACT_PROPS', props)
    for (const f in props) {
      Vue.set(state, f, props[f])
    }
    state._meta.dirty = true
  },
  [RESET_STATE] () {
    Vue.$log.debug('RESET_STATE')
    for (const f in state) {
      Vue.set(state, f, cloneDeep(initialState[f]))
    }
    state._meta.dirty = true
  },
  [RESET_PRIMARY] () {
    ['primaryContact', 'primaryContactRepresentative'].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  },
  [RESET_SECONDARY] () {
    ['secondaryContact', 'secondaryContactRepresentative'].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  },
  [RESET_TITLE] () {
    ['title', 'reportAreaDate', 'reportAmountDate', 'billingDate', 'validAfterDate'].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  },
  [RESET_RUNTIME] (contract) {
    [
      'startDate',
      'endDate',
      'isLifetime',
      'runtimeNote'
    ].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  },
  [RESET_DOCUMENTS] (contract) {
    ['documents', 'documentsSignedBy', 'documentAnnotation'].forEach(name => {
      Vue.set(state, name, cloneDeep(initialState[name]))
    })
    state._meta.dirty = true
  }
}

const getters = {
  id (contract) {
    return contract.id || null
  },
  contract (contract) {
    return contract
  },
  type (contract) {
    return contract.type
  },
  subtype (contract) {
    return contract.subtype
  },
  isSpecial (contract) {
    return contract.type === 'special'
  },
  isFundamental (contract) {
    return contract.type === 'fundamental'
  },
  isTest (contract) {
    return contract.type === 'test'
  },
  isLicence (contract) {
    return contract.type === 'licence'
  },
  isSublicence (contract) {
    return contract.type === 'sublicence'
  },
  isProduction (contract) {
    return contract.type === 'production'
  },
  hasAnnex (contract) {
    return Boolean(contract.supersededBy_id)
  },
  isAnnex (contract) {
    return Boolean(contract.superseds)
  },
  canHaveAnnex (contract, { isLicence, isSublicence, isTest, isProduction }) {
    return contract.id > 0 && (isLicence || isSublicence || isTest || isProduction)
  },
  canGenerateTemplate (contract, { hasAnnex, isLicence, isSublicence }) {
    return Boolean((isLicence || isSublicence) && !hasAnnex)
  },
  hasTemplateDates ({ reportAmountDate, reportAreaDate, billingDate }, { canGenerateTemplate }) {
    return canGenerateTemplate && Boolean(reportAmountDate && reportAreaDate && billingDate)
  },
  subjects (contract) {
    return contract.subjects || []
  },
  canSubjectsBeImported (contract, { isLicence, isSublicence, isProduction, isTest }) {
    return (isLicence || isSublicence || isProduction || isTest)
  },
  exists (contract) {
    return Boolean(contract.id)
  },
  canBeCopied (contract, { exists }) {
    return exists
  },
  isDeleted (contract, { exists, metaData }) {
    return exists && Boolean(metaData.deleted_at)
  },
  isFinalized (contract, { exists }) {
    return exists && Boolean(contract.documentsSignedBy.finale)
  },
  isDirtyContacts (contract) {
    return !haveEqualContacts(contract, contract._meta.originalContacts)
  },
  isDirty (contract, { isPristine }) {
    return !isPristine
  },
  isPristine (contract, { exists }) {
    return !exists || !contract._meta.dirty
  },
  isInValid (contract) {
    return isEmpty(contract.title) || isEmpty(contract.type)
  },
  isValid (contract, { isInValid }) {
    return !isInValid
  },
  dataOwner (state) {
    return state._meta.data_owned_by
  },
  metaData (state) {
    return state._meta
  }
}

const namespaced = true

export default {
  namespaced,
  state,
  actions,
  mutations,
  getters
}

export const TYPES = Object.freeze(['fundamental', 'licence', 'sublicence', 'production', 'test', 'special'])
export function availableTypes () {
  return TYPES.map(s => ({ value: s, text: this.$t(s) }))
}

export const SUBTYPES = Object.freeze(['cooperation', 'acquisition', 'transfer', 'agreement', 'breeding'])
export function availableSubTypes () {
  return SUBTYPES.map(s => ({ value: s, text: this.$t(s) }))
}
